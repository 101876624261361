<template>
  <module
      ref="module"
      id="tickets"
      titleIcon="fa fa-ticket"
      :title="$t('tickettxt')"
      :use-default-list="false"
  >
    <div slot="global">
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
      <div v-else class="row">

        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h5 slot="header" class="title title-up">{{ $t('tickettxt') }} </h5>

          <div class="row">
            <span>
              {{ $t('rmrespmsgtxt') }}
            </span>
          </div>

          <div class="row">
            <span v-on:click="copyit(respdata)" class="clickable">
              {{ respdata }}
            </span>
          </div>

          <input id="clipit" type="text" v-model="clipboard" style="display: none">

          <template slot="footer">
            <p-button type="success" v-on:click="modalconfirm = false" link style="margin: auto !important;">
              {{ $t('okaytxt') }}
            </p-button>
          </template>
        </modal>

        <div class="col-md-12">
          <div class="card-body">
            <div class="card-header">
              <h4 class="card-title"><b> {{ $t('titletxt') }} </b></h4>
              <span class="card-description"><i>{{ $t('titledesctxt') }} </i></span>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <textarea
                    v-model="form.subject"
                    v-validate="modelValidation.subject"
                    class="form-control"
                    rows="1"
                    style="line-height: 100%"
                    :maxlength="255"
                />
              </div>
            </div>
            <div class="card-header">
              <h4 class="card-title"><b>{{ $t('bugtype') }}</b></h4>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <el-select class="select" v-validate="modelValidation.bugtype" style="width: 100%;"
                           size="large" v-model="form.type">
                  <el-option v-for="option in types" class="select"
                             :value="option.code" :label="option.name" :key="option.code">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="card-header">
              <h4 class="card-title"><b>{{ $t('issuedesctitle') }}</b></h4>
              <span class="card-description"><i>{{ $t('issuedescval') }}</i></span>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                  <textarea v-model="form.desc1" v-validate="modelValidation.desc1" id="description"
                            class="form-control" rows="5"></textarea>
              </div>
            </div>
            <div class="card-header">
              <h4 class="card-title"><b>{{ $t('repissuetitle') }}</b></h4>
              <span class="card-description"><i>{{ $t('repissueval') }}</i></span>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                  <textarea v-model="form.desc2" v-validate="modelValidation.desc2" id="description"
                            class="form-control" rows="5"></textarea>
              </div>
            </div>
            <div class="card-header">
              <h4 class="card-title"><b>{{ $t('issuedatatitle') }}</b></h4>
              <span class="card-description"><i>{{ $t('issuedataval') }}</i></span>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <textarea
                    v-validate="modelValidation.desc3"
                    v-model="form.desc3"
                    class="form-control"
                    rows="1"
                    style="line-height: 100%"
                />
              </div>
            </div>
            <div class="card-header">
              <span class="card-description"><i>{{ $t('attachdesc') }}</i></span>
            </div>
            <div class="form-group" style="position: relative;">
              <div class="col-md-3" style="display: flex; align-items: center;">
                <p-button
                    nativeType="submit"
                    @click.prevent="loadfiles"
                    slot="footer"
                    type="info"
                    block v-if="inprogress === false"
                >
                  {{ $t('attachbutton') }}
                </p-button>
                <input
                    ref="fileInput"
                    type="file"
                    style="display: none;"
                    @change="handleFileChange"
                    multiple
                    accept="image/*, video/*"
                />
                <div v-if="msgAttach" style="position: absolute; top: 35%; left: 100%; white-space: nowrap; margin-left: 20%; color: green; font-style: italic; font-size: 15px">
                  {{ msgAttach }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-3" style="margin-left: auto">
                <div v-if="inprogress">
                  <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
                </div>
                <p-button
                    nativeType="submit"
                    @click.prevent="validate"
                    slot="footer"
                    type="success"
                    block
                    v-if="inprogress == false">
                  {{ $t('submit') }}
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
import {Card, Modal} from 'src/components/UIComponents'
import {Select, Option} from 'element-ui'
import axios from 'axios'

const user_data = JSON.parse(localStorage.getItem('user-info'));
const timeoutcount = 5000;

export default {
  components: {
    Modal,
    Card,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      form: {
        subject: '',
        description: '',
        desc1: '',
        desc2: '',
        desc3: '',
        type: ''
      },
      modalconfirm: false,
      clipboard: '',
      respdata: '',
      msgAttach: '',
      modelValidation: {
        subject: {
          required: true,
          min: 1
        },
        desc1: {
          required: true,
          min: 1
        },
        desc2: {
          required: true,
          min: 1
        },
        desc3: {
          required: true,
          min: 1
        },
        bugtype: {
          required: true
        },
      },
      ticketinfo: this.$t('ticketinfo'),
      inprogresstxt: this.$t('loadingtxt'),
      inprogress: false,
      subject: this.$t('subject'),
      types: [
        {
          name: this.$t('bugtcopy'),
          code: 'Copy'
        },
        {
          name: this.$t('bugtfunf'),
          code: 'FollowUnfollow'
        },
        {
          name: 'Front',
          code: 'Front'
        },
        {
          name: this.$t('vps'),
          code: 'VPS'
        },
        {
          name: this.$t('mats'),
          code: 'MATs'
        },
        {
          name: this.$t('deposits'),
          code: 'Depósitos'
        },
        {
          name: this.$t('performancetxt'),
          code: 'Performance'
        },
        {
          name: this.$t('bugtbill'),
          code: 'Cobranças'
        },
        {
          name: this.$t('bugtmob'),
          code: 'Mobile'
        },
        {
          name: this.$t('othertxt'),
          code: 'Outros'
        }
      ],
      files: [],
    }
  },
  methods: {
    handleFileChange(event) {
      this.files = event.target.files
      this.msgAttach = this.files.length.toString() + ' ' + this.$t('filesattach')
    },
    async createRedmineIssue() {
      const formData = new FormData()

      this.form.description = "### Usuário criador: " +
          user_data['user_metadata']['first_name'] + " " + user_data['user_metadata']['last_name'] +
          "\n### Problema e resultado esperado\n" + this.form.desc1 + "\n### Como replicar o problema\n" +
          this.form.desc2 + "\n### Dados da conta para simular o problema\n" + this.form.desc3

      formData.append("subject", this.form.subject)
      formData.append("description", this.form.description)
      formData.append("bugtype", this.form.type)

      Array.from(this.files).forEach(file => {
        formData.append('files', file);
      })

      try {
        const response = await axios.post('https://integration.hokocloud.com/api/redmine/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer Auha88daHADuahwDQ0ZD877siDii8dabASJ7DSY6GSD4S65DADg9s'
          }
        })

        if (response.data === 'error') {
          this.$toast.error('Failed, check response on Integration project.')
        } else {
          this.respdata = response.data
          this.modalconfirm = true
        }

        setTimeout(this.closeAlert, timeoutcount)

      } catch (error) {
        console.error('Error:', error)
      }
    },
    copyit(value) {
      let cb = document.getElementById("clipit");
      cb.style.display = 'block';
      cb.value = value;
      cb.select();
      document.execCommand('copy');
      cb.style.display = 'none';
      this.$toast.success(`${value} Copied!`)
    },
    closeAlert() {
      this.form.subject = ''
      this.form.description = ''
      this.form.desc1 = ''
      this.form.desc2 = ''
      this.form.desc3 = ''
      this.form.type = ''
      this.files = []
      this.msgAttach = ''
    },
    loadfiles() {
      this.$refs.fileInput.click()
    },
    async validate() {
      if (this.form.desc1 && this.form.desc2 && this.form.desc3 && this.form.subject && this.form.type) { // && this.files.length > 0) {
        await this.createRedmineIssue();
      } else {
        this.$toast.error(this.$t('populatefieldbelow'))
      }
    },
  },
}
</script>
<style>
.card-title,
.card-description {
  margin: 0;
  padding: 0;
}
</style>
